const v1 = '/v1';
const apiUrl = (version: string): string => `${version}`;

/** Area unit */
const area_unit = `${apiUrl(v1)}/unit`;

export const url_unit_info = (unitId: number) => `${area_unit}/${unitId}/info`;

export const url_unit_get_unit_structure = (unitId: number) =>
	`${area_unit}/${unitId}/structure`;

export const url_unit_get_unit_register = `${area_unit}/register`;

export const url_unit_get_unit_parts = (unitId: number) =>
	`${area_unit}/${unitId}/parts`;

export const url_unit_delete = (unitId: number) => `${area_unit}/${unitId}`;

export const url_unit_configuration = `${area_unit}/configuration`;

/** Area Files **/
export const area_files = `${apiUrl(v1)}/file`;

export const getUrlDocumentDownloadLink = (documentId: number) =>
	`${
		import.meta.env.VITE_PC_API_HOST ?? ''
	}${area_files}/document/${documentId}`;

export const getUrlFileSetDownload = (fileSetId: number, fileId: number) =>
	`${
		import.meta.env.VITE_PC_API_HOST ?? ''
	}${area_files}?FileSetId=${fileSetId}&FileId=${fileId}`;

export const getUrlServiceProtocolFileDownload = (fileId: number) =>
	`${
		import.meta.env.VITE_PC_API_HOST
	}${area_files}/service-protocol/${fileId}`;

export const url_components_and_software_csv = `${apiUrl(
	v1
)}/vda/components-and-software-csv`;

export const getUrlCSVDownload = (
	chassisSeries: string,
	chassisNumber: number
) =>
	`${
		import.meta.env.VITE_PC_API_HOST ?? ''
	}${url_components_and_software_csv}?chassisSeries=${chassisSeries}&chassisNumber=${chassisNumber}`;

/** VPPN */
export const url_vppn_get_token = `${window.location.origin}${
	import.meta.env.BASE_URL
}`;
export const suburl_vpp_refresh_token = '/Auth/GetVppnToken';
export const url_vppn_refresh_token = `${
	import.meta.env.VITE_VPPN_AUTH_SERVER || url_vppn_get_token
}${suburl_vpp_refresh_token}`;
