import { Button, ButtonOwnProps } from '@mui/material';
import { ReactNode, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';

import { ArrowRight } from 'assets/volvoIcons';
import { Id } from 'domain/Id';
import { ServiceProtocolStatus } from 'domain/serviceProtocol';
import { getLinkOpenExistingServiceProtocol } from 'library/components/link';
import {
	generateServiceProtocolUrl,
	serviceProtocolView,
} from 'main/routes/urls/RouteUrls';

interface ServiceProtocolDetailsLinkProps
	extends Id,
		Pick<ButtonOwnProps, 'color' | 'variant'> {
	Icon?: ReactNode;
	status: ServiceProtocolStatus;
	drivelineId: number;
}

const VITE_IS_SERVICE_PROTOCOL_VIEW = import.meta.env
	.VITE_IS_SERVICE_PROTOCOL_VIEW;

export const ServiceProtocolDetailsLink = memo(
	({
		color,
		drivelineId,
		Icon = <ArrowRight />,
		id,
		status,
		variant = 'text',
	}: ServiceProtocolDetailsLinkProps) => {
		const { t } = useTranslation(['commonButton']);

		const newServiceProtocolPath = generatePath(
			`/${status === 'Initiated' ? generateServiceProtocolUrl : serviceProtocolView}?drivelineId=${drivelineId}`,
			{
				serviceProtocolId: id.toString(),
			}
		);

		return (
			<>
				{VITE_IS_SERVICE_PROTOCOL_VIEW === 'true' ? (
					<Button
						color={color}
						component={Link}
						endIcon={Icon}
						to={newServiceProtocolPath}
						sx={{ mr: 2 }}
						variant={variant}>
						{status === 'Initiated'
							? t('commonButton:edit')
							: t('commonButton:view')}
					</Button>
				) : (
					<Button
						color={color}
						sx={{ mr: 2 }}
						variant={variant}
						href={getLinkOpenExistingServiceProtocol(id)}>
						{status === 'Initiated'
							? t('commonButton:edit')
							: t('commonButton:view')}
					</Button>
				)}
			</>
		);
	}
);

ServiceProtocolDetailsLink.displayName = 'ServiceProtocolDetailsLink';
