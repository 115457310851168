import { Box, Button, Typography } from '@mui/material';
import { HTMLAttributeAnchorTarget } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, createSearchParams } from 'react-router-dom';

import { BaseDialog } from 'library/components/controls';
import {
	getLinkCreateNewServiceProtocol,
	getLinkOpenExistingServiceProtocol,
} from 'library/components/link';
import { useBlockClick } from 'library/hooks';
import { serviceProtocolArea } from 'main/routes/urls/RouteUrls';

// Is new feature available - #705677 - TODO: REMOVE AFTER PROD RELEASE
const VITE_IS_SERVICE_PROTOCOL_VIEW = import.meta.env
	.VITE_IS_SERVICE_PROTOCOL_VIEW;

export type ServiceProtocolIdProp = {
	serviceProtocolId: number;
};

export const GenerateProtocolWarningDialog = ({
	serviceProtocolId,
	serviceScheduledDate,
	chassisId,
	drivelineId,
	onClose,
	linkTarget = '_self',
}: ServiceProtocolIdProp & {
	chassisId: string;
	drivelineId: number;
	onClose: () => void;
	serviceScheduledDate?: Date | null;
	linkTarget?: HTMLAttributeAnchorTarget | undefined;
}): JSX.Element => {
	const { t } = useTranslation(['common', 'serviceProtocol']);
	const { disabled, handleClick } = useBlockClick();

	// disable button if the old UI page will open in the same tab
	const shouldDisableButton = linkTarget == '_self' && disabled;

	return (
		<BaseDialog
			title={t('common:warning') + ` - ${chassisId}`}
			onClose={onClose}
			sx={{
				'& .base-dialog__title': {
					bgcolor: 'background.default',
				},
			}}>
			<Typography py={2}>
				{t('serviceProtocol:generate-popup-message')}
			</Typography>

			<Box display="flex" gap={1}>
				<Button
					variant="outlined"
					color="secondary"
					target={linkTarget}
					disabled={shouldDisableButton}
					href={getLinkOpenExistingServiceProtocol(serviceProtocolId)}
					onClick={handleClick}>
					{t('serviceProtocol:generate-popup-open-protocol-button')}
				</Button>
				{VITE_IS_SERVICE_PROTOCOL_VIEW === 'true' ? (
					<Button
						variant="outlined"
						component={Link}
						to={
							`/${serviceProtocolArea}/generate?` +
							createSearchParams({
								drivelineId: drivelineId?.toString(),
								serviceDate:
									serviceScheduledDate?.toISOString() ?? '',
							}).toString()
						}>
						{t(
							'serviceProtocol:generate-popup-create-protocol-button'
						)}
					</Button>
				) : (
					<Button
						variant="outlined"
						color="secondary"
						target={linkTarget}
						disabled={shouldDisableButton}
						href={getLinkCreateNewServiceProtocol(
							chassisId,
							serviceScheduledDate
						)}
						onClick={handleClick}>
						{t(
							'serviceProtocol:generate-popup-create-protocol-button'
						)}
					</Button>
				)}
			</Box>
		</BaseDialog>
	);
};
