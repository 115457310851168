import { Delete } from '@mui/icons-material';
import {
	Box,
	Button,
	Dialog,
	Slider,
	Theme,
	Typography,
	styled,
	useMediaQuery,
} from '@mui/material';
import { isEqual, noop } from 'lodash';
import {
	ChangeEvent,
	PropsWithChildren,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import Cropper, { Area } from 'react-easy-crop';
import { useTranslation } from 'react-i18next';

import { useApiDownloadImage } from 'api/queries';
import {
	FileUploadButton,
	FloatingButtonBox,
	GetCroppedImgReturnType,
	IMAGE_EXTENSIONS_STRING,
	MAX_FILE_LIMIT,
	MAX_FILE_SIZE_MB,
	PageButtons,
	emptyFilteredFiles,
	getCroppedImg,
	readFile,
	validateImages,
} from 'library/components';
import { FileWarningDialog, FilteredList, useDialogState } from 'library/hooks';
import { useCheckConsoleDebuggingReadAccess } from 'main/auth/checkUserAccess';

const ANGLES_MARKS = [
	{
		value: 0,
		label: '0°',
	},
	{
		value: 90,
		label: '90°',
	},
	{
		value: 180,
		label: '180°',
	},
	{
		value: 270,
		label: '270°',
	},
];
const DEFAULT_CROP = { x: 0, y: 0 };
const DEFAULT_ROTATION = 0;
const DEFAULT_ZOOM = 1;

const StyledButtonWrapper = styled('div')({
	display: 'flex',
	width: '100%',
});

const StyledMobileButtonWrapper = styled('div')({
	display: 'grid',
	gridAutoFlow: 'column',
	gridAutoColumns: '1fr',
});

interface ButtonWrapperProp {
	disabled?: boolean;
	isDelete: boolean;
	imageSrc?: string | null;
	onDelete?: () => void;
}

const ButtonWrapper = ({
	disabled = false,
	children,
	isDelete,
	imageSrc,
	onDelete = noop,
}: PropsWithChildren<ButtonWrapperProp>) => {
	const isMobile = useMediaQuery(({ breakpoints: { down } }: Theme) =>
		down('md')
	);
	const { t } = useTranslation(['commonButton']);
	const containerStyle = useMemo(
		() => (imageSrc ? 'end' : 'center'),
		[imageSrc]
	);
	const sx = useMemo(
		() => ({
			justifyContent: isDelete ? 'space-between' : containerStyle,
		}),
		[containerStyle, isDelete]
	);
	const Wrapper = useMemo(
		() => (isMobile ? StyledMobileButtonWrapper : StyledButtonWrapper),
		[isMobile]
	);

	return (
		<Wrapper sx={isMobile ? {} : sx}>
			{isDelete && (
				<Button
					disabled={disabled}
					startIcon={<Delete />}
					variant="contained"
					color="primary"
					size="large"
					onClick={onDelete}>
					{t('commonButton:delete')}
				</Button>
			)}
			{children}
		</Wrapper>
	);
};

export const UploadPhotoDialog = ({
	imageId,
	onClose,
	onSave,
	open,
}: {
	imageId?: number;
	onClose: () => void;
	onSave: (blob?: GetCroppedImgReturnType, filename?: string) => void;
	open: boolean;
}) => {
	const isMobile = useMediaQuery(({ breakpoints: { down } }: Theme) =>
		down('md')
	);

	const { t } = useTranslation(['commonButton', 'component']);
	const { checkConsoleDebuggingReadAccess } =
		useCheckConsoleDebuggingReadAccess();
	const source = useApiDownloadImage(imageId);
	const {
		handleClose: handleWarningDialogClose,
		handleOpen: handleWarningDialogOpen,
		open: warningDialogOpen,
	} = useDialogState();
	const [imageSrc, setImageSrc] = useState<string | null | undefined>();
	const [filteredFiles, setFilteredFiles] =
		useState<FilteredList>(emptyFilteredFiles);
	const [crop, setCrop] = useState(DEFAULT_CROP);
	const [rotation, setRotation] = useState(DEFAULT_ROTATION);
	const [zoom, setZoom] = useState(DEFAULT_ZOOM);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(
		null
	);
	const [isSave, setIsSave] = useState(false);
	const [isProcessing, setIsProcessing] = useState(false);
	const [isDelete, setIsDelete] = useState(!!imageId && !isSave);
	const fullScreen = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm')
	);

	useEffect(() => {
		if (source) {
			setImageSrc(source);
		}
	}, [source]);

	const handleSave = async () => {
		setIsProcessing(true);

		if (imageSrc && croppedAreaPixels) {
			try {
				const croppedImage = await getCroppedImg(
					imageSrc,
					croppedAreaPixels,
					rotation,
					{ horizontal: false, vertical: false },
					true
				);

				if (croppedImage) {
					setIsProcessing(false);
					onSave(croppedImage);
					return;
				}
			} catch (e) {
				checkConsoleDebuggingReadAccess() && console.error(e);
			}
		} else if (imageId) {
			setIsProcessing(false);
			onSave(); // delete
		}
	};

	const handleClose = () => {
		setImageSrc(null);
		onClose();
	};

	const handleInput = async ({
		target: { files },
	}: ChangeEvent<HTMLInputElement>) => {
		const validationResult = validateImages(files);

		if (!validationResult) {
			const [file] = files || [];

			const imageDataUrl = await readFile(file);
			// mobile phone image rotation can be done using css3
			// https://developer.mozilla.org/en-US/docs/Web/CSS/image-orientation
			if (imageDataUrl) {
				setImageSrc(imageDataUrl.toString());
			}
			setIsSave(true);
		} else {
			setFilteredFiles((current) => ({
				...current,
				[validationResult]: Array.from(files || []),
			}));
			handleWarningDialogOpen();
		}
	};

	const handleCropComplete = (
		_croppedArea: Area,
		croppedAreaPixelsValue: Area
	) => {
		setCroppedAreaPixels(croppedAreaPixelsValue);
	};

	const handleOnDelete = useCallback(() => {
		setImageSrc(null);
		setIsDelete(false);
		setIsSave(true);
	}, []);

	const warningDialogClose = useCallback(() => {
		setFilteredFiles(emptyFilteredFiles);
		handleWarningDialogClose();
	}, []);

	return (
		<>
			<Dialog onClose={handleClose} open={open} fullScreen={fullScreen}>
				<Box
					minWidth={300}
					sx={{
						width: 600,
						boxSizing: 'border-box',
						'.MuiDialog-paperFullScreen > &': {
							width: isMobile ? '100%' : 300,
						},
					}}
					p={3}>
					<FloatingButtonBox
						photoUrl={imageSrc}
						position="relative"
						width={1}
						height={350}
						sx={{
							alignItems:
								imageId || imageSrc ? 'flex-end' : 'center',
						}}>
						{imageSrc && (
							<Cropper
								image={imageSrc}
								crop={crop}
								rotation={rotation}
								zoom={zoom}
								aspect={16 / 9}
								onCropChange={setCrop}
								onRotationChange={setRotation}
								onCropComplete={handleCropComplete}
								onZoomChange={setZoom}
							/>
						)}
						<ButtonWrapper
							isDelete={isDelete}
							imageSrc={imageSrc}
							onDelete={handleOnDelete}
							disabled={isProcessing}>
							<FileUploadButton
								id="upload-photo-button"
								filesAccepted="image/*"
								onChange={handleInput}
								disabled={isProcessing}>
								{t('component:select-picture-button-label')}
							</FileUploadButton>
						</ButtonWrapper>
					</FloatingButtonBox>
					<Box m={1}>
						<Typography variant="overline">
							{t('component:zoom-label')}
						</Typography>
						<Slider
							disabled={!imageSrc}
							value={zoom}
							min={1}
							max={3}
							step={0.01}
							aria-labelledby="Zoom"
							onChange={(_, zoomValue: unknown) =>
								setZoom(zoomValue as number)
							}
						/>
					</Box>
					<Box m={1}>
						<Typography variant="overline">
							{t('component:rotation-label')}
						</Typography>
						<Slider
							disabled={!imageSrc}
							value={rotation}
							min={0}
							max={360}
							step={1}
							marks={ANGLES_MARKS}
							aria-labelledby="Rotation"
							onChange={(_, rotationValue: unknown) =>
								setRotation(rotationValue as number)
							}
						/>
					</Box>
					<PageButtons
						primaryButtonLabel={t('commonButton:save')}
						secondaryButtonLabel={t('commonButton:cancel')}
						primaryButtonAction={handleSave}
						secondaryButtonAction={handleClose}
						primaryButtonDisabled={
							!isSave &&
							zoom === DEFAULT_ZOOM &&
							rotation === DEFAULT_ROTATION &&
							isEqual(crop, DEFAULT_CROP)
						}
						displayLoadingIndicator={isProcessing}
					/>
				</Box>
			</Dialog>
			<FileWarningDialog
				amount={MAX_FILE_LIMIT}
				extensions={IMAGE_EXTENSIONS_STRING}
				filteredFiles={filteredFiles}
				onClose={warningDialogClose}
				open={warningDialogOpen}
				size={MAX_FILE_SIZE_MB}
				sx={{ zIndex: 1500 }}
			/>
		</>
	);
};
