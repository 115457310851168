import { Box, Paper, Typography } from '@mui/material';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { last, merge } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef } from 'react';
import { ValidationValueMessage } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useProductCenterApi } from 'api';
import { mapToUploaderName } from 'domain/user/User';
import { Namespace, ParseKeys } from 'i18next';
import {
	ClientFileDisplay,
	FileDragBox,
	IInput,
} from 'library/components/controls';
import {
	FileWarningDialog,
	useUploadController,
} from 'library/hooks/UseUploadController';
import { selectUser } from 'library/redux/application';
import {
	FileData,
	FileUploadState,
	mapFileToFileData,
	selectAttachmentFolderName,
	selectFileUploaded,
} from 'library/redux/fileUpload';
import { useAppDispatch, useAppSelector } from 'library/redux/hooks';
import { RootState } from 'store';

export interface IFileUploadStateHandlers {
	attachmentFolderCreated: ActionCreatorWithPayload<string, string>;
	fileDeleted: ActionCreatorWithPayload<string, string>;
	fileUploaded: ActionCreatorWithPayload<FileData, string>;
	fileStateSelector: (s: RootState) => FileUploadState;
}

export interface IUploadFileSectionProps extends IInput {
	text?: ParseKeys<Namespace>;
	title?: ParseKeys<Namespace>;
	isError?: boolean;
	isValidationOnUpload?: boolean;
	fileUploadStateHandlers: IFileUploadStateHandlers;
	validate: (handler: () => boolean) => void;
	validation?: {
		min?: ValidationValueMessage<number>;
		max?: ValidationValueMessage<number>;
	};
}

const allowedExtensions =
	/\.(pdf|doc|docx|xls|xlsx|gif|png|jpg|jpeg|tiff|tif|slk|vlr)$/;
const maxFileCount = 10;

const connectionTimeout = 600000;

interface WaitingFile {
	resolve: (value: string | PromiseLike<string>) => void;
}

export const UploadFileSection = ({
	text = 'fileUpload:upload-files-text',
	title = 'fileUpload:upload-files-title',
	fileUploadStateHandlers: handlers,
	isError = false,
	isValidationOnUpload = false,
	validate,
	validation = {},
	...props
}: IUploadFileSectionProps): JSX.Element => {
	const { t } = useTranslation<Namespace>();
	const { api } = useProductCenterApi();

	const dispatch = useAppDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const stateUser = useAppSelector(selectUser);
	const filesAlreadyUploaded = useAppSelector((state) =>
		selectFileUploaded(handlers.fileStateSelector(state))
	).filter(({ isAdditional }) => !isAdditional);
	const attachmentFolderName = useAppSelector((state) =>
		selectAttachmentFolderName(handlers.fileStateSelector(state))
	);
	const waitingFiles = useRef<WaitingFile[]>([]);

	const getValidationMessage = (
		validator: (value: number) => boolean,
		rule?: ValidationValueMessage<number>
	) => (rule && validator(rule.value) ? rule.message : undefined);

	const handleValidation = (newFiles: File[] = []) => {
		const minValidationMessage = getValidationMessage(
			(value: number) =>
				filesAlreadyUploaded.length + newFiles.length < value,
			validation.min
		);
		const maxValidationMessage = getValidationMessage(
			(value: number) =>
				filesAlreadyUploaded.length + newFiles.length > value,
			validation.max
		);

		if (minValidationMessage || maxValidationMessage) {
			enqueueSnackbar(
				t(
					(minValidationMessage ||
						maxValidationMessage) as ParseKeys<Namespace>
				),
				{
					variant: 'error',
				}
			);
			return false;
		}

		if (selectAllFiles.length === 0) {
			return true;
		}

		if (selectAllFiles.some((file) => file.failReason)) {
			enqueueSnackbar(t('fileUpload:error-file-uploading-failed'), {
				variant: 'error',
			});
		}

		if (
			selectAllFiles.some(
				(file) =>
					!file.failReason &&
					file.percentCompleted > 0 &&
					file.percentCompleted < 100
			)
		) {
			enqueueSnackbar(t('fileUpload:error-file-being-uploaded'), {
				variant: 'error',
			});
		}

		return false;
	};

	const {
		filteredList,
		selectAllFiles,
		handleNewFiles,
		handleRetryUpload,
		handleProgressUpdate,
		handleDeleteFile,
		handleWarningDialogClose,
		uploadingFilenames,
	} = useUploadController({
		allowedExtensions,
		maxFileCount,
		uploadFile: async (file) => {
			let folderName = attachmentFolderName;

			// check if it's nth file in a batch - first must upload before other to get attachment folder
			if (!folderName && uploadingFilenames[0] !== file.name) {
				folderName = await new Promise<string>((resolve) => {
					waitingFiles.current.push({
						resolve,
					});
				});

				if (!folderName) {
					return Promise.reject(
						'First file has not been uploaded. Attachment folder has not been created to upload the rest files of the batch'
					);
				}
			}

			return api.uploadTemporaryFile(
				{ file },
				{ attachmentsFolder: folderName },
				{
					onUploadProgress: ({ loaded, total }) => {
						if (!total) {
							return;
						}

						const percentCompleted = Math.round(
							(loaded * 100) / total
						);
						handleProgressUpdate(file, percentCompleted);
					},
					timeout: connectionTimeout,
				}
			);
		},
		fileUploaded: (file, response) => {
			const responseTyped = response;
			if (responseTyped) {
				if (
					!attachmentFolderName &&
					responseTyped.data.attachmentsFolder
				) {
					dispatch(
						handlers.attachmentFolderCreated(
							responseTyped.data.attachmentsFolder
						)
					);
				}

				const serverName = last(
					responseTyped.data.currentlyUploadedFiles?.filter((e) =>
						e.includes(file.name)
					)
				);

				if (serverName) {
					dispatch(
						handlers.fileUploaded(
							mapFileToFileData(
								file,
								serverName,
								mapToUploaderName(stateUser)
							)
						)
					);

					handleDeleteFile(file);
				}
			}
		},
		fileExistsAlready: (file) =>
			filesAlreadyUploaded.findIndex(
				(existingFile) => existingFile.name === file.name
			) >= 0,
	});

	validate(handleValidation);

	useEffect(() => {
		const failedUploadingFileIndex = selectAllFiles.findIndex(
			({ state }) => state === 'failed'
		);

		// if there are awaiting files, then release them in case of having:
		// - an attachment upload folder - that means first file has been uploaded correctly
		// - first file hasn't been uploaded - there is an error
		if (
			waitingFiles.current.length &&
			(attachmentFolderName || !failedUploadingFileIndex)
		) {
			waitingFiles.current.forEach(({ resolve }) => {
				resolve(attachmentFolderName);
			});
			waitingFiles.current = [];
		}
	}, [attachmentFolderName, selectAllFiles]);

	const handleDeleteUploadedFile = useCallback(
		(file: FileData) => {
			dispatch(handlers.fileDeleted(file.name));
			URL.revokeObjectURL(file.fileUrl);
		},
		[dispatch, handlers]
	);

	return (
		<>
			{filteredList && (
				<FileWarningDialog
					filteredFiles={filteredList}
					onClose={handleWarningDialogClose}
				/>
			)}

			<Box
				sx={({
					palette: {
						error: { main },
					},
				}) =>
					merge({
						...(props.sx || {}),
						...(isError ? { border: `1px solid ${main}` } : {}),
					})
				}
				display="grid"
				gridTemplateColumns={{
					xs: 'repeat(1, 1fr)',
					sm: 'repeat(2, 1fr)',
				}}
				gap={2}
				component={Paper}
				variant="flatGrey"
				p={2}>
				<Box>
					<Typography variant="h5" pb={1}>
						{t(title)}
						{validation.min && (
							<Typography component="span" color="error.main">
								{' *'}
							</Typography>
						)}
					</Typography>
					<Typography variant="body2">{t(text)}</Typography>
				</Box>
				<FileDragBox
					onNewFilesUploaded={(newFiles) => {
						if (
							!isValidationOnUpload ||
							handleValidation(newFiles)
						) {
							handleNewFiles({
								newFiles,
								externalFileCount: filesAlreadyUploaded.length,
							});
						}
					}}
				/>
				<Box
					margin={
						filesAlreadyUploaded.length
							? (theme) => ({
									xs: theme.spacing(2, 0, 0, 0),
								})
							: {}
					}
					gridColumn={{ sm: 'span 2' }}
					sx={{ '&>*': { marginTop: 2 } }}>
					{selectAllFiles.map((fileData) => (
						<ClientFileDisplay
							key={fileData.file.name}
							name={fileData.file.name}
							type={fileData.file.type}
							size={fileData.file.size}
							completed={false}
							percentCompleted={fileData.percentCompleted}
							failed={fileData.state === 'failed'}
							onDeleteClick={() =>
								handleDeleteFile(fileData.file)
							}
							onRetryClick={() =>
								handleRetryUpload(fileData.file)
							}
						/>
					))}
					{filesAlreadyUploaded.map((fileData) => (
						<ClientFileDisplay
							key={fileData.name}
							name={fileData.name}
							type={fileData.type}
							size={fileData.size}
							url={fileData.fileUrl}
							completed
							onDeleteClick={() =>
								handleDeleteUploadedFile(fileData)
							}
						/>
					))}
				</Box>
			</Box>
		</>
	);
};
