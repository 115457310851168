import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { GridActionsCellItemProps } from '@mui/x-data-grid-pro';
import { ReactNode, forwardRef } from 'react';

import { BlockedDoubleClickButton } from '../buttons/BlockedDoubleClickButton';

export const ButtonActionItem = forwardRef<
	HTMLLIElement | null,
	Pick<GridActionsCellItemProps, 'showInMenu' | 'label'> & {
		onClick: () => void;
		startIcon: ReactNode;
	}
>(({ onClick, label, startIcon }, ref): JSX.Element => {
	return (
		<MenuItem ref={ref} sx={{ padding: 0 }}>
			<BlockedDoubleClickButton
				variant="text"
				disableRipple
				onClick={onClick}
				sx={{
					flex: '1 1 auto',
					display: 'flex',
					alignItems: 'center',
					padding: (theme) => theme.spacing(0.75, 2),
					textTransform: 'none',
					textAlign: 'left',
				}}>
				<ListItemIcon>{startIcon}</ListItemIcon>
				<ListItemText>{label}</ListItemText>
			</BlockedDoubleClickButton>
		</MenuItem>
	);
});

ButtonActionItem.displayName = 'ButtonActionItem';
