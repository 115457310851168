import imageCompression from 'browser-image-compression';
import { Area } from 'react-easy-crop';

import { FilteredList, FilteredReason } from 'library/hooks';

import { MAX_FILE_LIMIT, MAX_FILE_SIZE, validateFiles } from './functions';

const IMAGE_COMPRESSION_OPTIONS = {
	maxSizeMB: 1,
	maxWidthOrHeight: 1920,
	useWebWorker: false,
};

export const IMAGE_TYPE = 'image/jpeg';
export const IMAGE_EXTENSIONS =
	/\.(tiff|jfif|bmp|gif|svg|png|jpeg|svgz|jpg|webp|ico|xbm|dip|pjp|apng|tif|pjpeg|avif)$/;
export const IMAGE_EXTENSIONS_STRING = IMAGE_EXTENSIONS.toString()
	.replaceAll(/[.()$\\/]/g, '')
	.replaceAll(/^|\|/g, ' .');
export const emptyFilteredFiles: FilteredList = {
	AlreadyExists: [],
	TooBig: [],
	TooMany: [],
	WrongExtension: [],
};

const createImage = (url: string): Promise<HTMLImageElement> =>
	new Promise((resolve, reject) => {
		const image = new Image();
		image.addEventListener('load', () => resolve(image));
		image.addEventListener('error', (error) => reject(error));
		image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
		image.src = url;
	});

function getRadianAngle(degreeValue: number): number {
	return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
function rotateSize(width: number, height: number, rotation: number) {
	const rotRad = getRadianAngle(rotation);

	return {
		width:
			Math.abs(Math.cos(rotRad) * width) +
			Math.abs(Math.sin(rotRad) * height),
		height:
			Math.abs(Math.sin(rotRad) * width) +
			Math.abs(Math.cos(rotRad) * height),
	};
}

export const getPictureFileFromBlob = (blob: Blob) =>
	new File([blob], 'picture', {
		lastModified: new Date().getTime(),
		type: blob.type,
	});

export type GetCroppedImgReturnType = {
	fileUrl: string | null;
	file: Blob;
};

export const compressImage = async (blob: Blob) => {
	let file = blob;
	try {
		file = await imageCompression(
			new File([blob], 'compressedImage', {
				type: IMAGE_TYPE,
			}),
			IMAGE_COMPRESSION_OPTIONS
		);
	} catch (error) {
		console.log(`Image can't be compressed: ${error}`);
	}
	return file;
};

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg(
	imageSrc: string,
	pixelCrop: Area,
	rotation = 0,
	flip = { horizontal: false, vertical: false },
	withCompression = false
): Promise<GetCroppedImgReturnType | null> {
	const image = await createImage(imageSrc);
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	if (!ctx) {
		return null;
	}

	const rotRad = getRadianAngle(rotation);

	// calculate bounding box of the rotated image
	const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
		image.width,
		image.height,
		rotation
	);

	// set canvas size to match the bounding box
	canvas.width = bBoxWidth;
	canvas.height = bBoxHeight;
	ctx.fillStyle = '#FFF';
	ctx.fillRect(0, 0, bBoxWidth, bBoxHeight);

	// translate canvas context to a central location to allow rotating and flipping around the center
	ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
	ctx.rotate(rotRad);
	ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
	ctx.translate(-image.width / 2, -image.height / 2);

	// draw rotated image
	ctx.drawImage(image, 0, 0);

	// croppedAreaPixels values are bounding box relative
	// extract the cropped image using these values
	const data = ctx.getImageData(
		pixelCrop.x,
		pixelCrop.y,
		pixelCrop.width,
		pixelCrop.height
	);

	// set canvas width to final desired crop size - this will clear existing context
	canvas.width = pixelCrop.width;
	canvas.height = pixelCrop.height;

	// paste generated rotate image at the top left corner
	ctx.putImageData(data, 0, 0);

	// As Base64 string
	// return canvas.toDataURL('image/jpeg');

	// As a blob
	return new Promise((resolve) => {
		canvas.toBlob(async (blob) => {
			if (blob) {
				const file = withCompression ? await compressImage(blob) : blob;
				resolve({ fileUrl: URL.createObjectURL(file), file });
			}
		}, IMAGE_TYPE);
	});
}

export const validateImages = (
	files: FileList | null
): FilteredReason | undefined =>
	files
		? validateFiles(
				Array.from(files),
				[],
				MAX_FILE_LIMIT,
				MAX_FILE_SIZE,
				IMAGE_EXTENSIONS
			)
				?.keys()
				.next().value
		: undefined;
